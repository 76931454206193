@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

.services {
  direction: rtl;
}
.contact {
  direction: rtl;
}

.footer__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem;
  font-family: "Noto Sans Hebrew", sans-serif;
}
.footer__container ul li {
  margin: 1rem 0;
}
.footer__socials li {
  display: inline-block;
  margin-right: 1rem !important;
  padding: 2px;
}
.footer__socials li a {
  font-size: 1.25rem;
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .footer__container {
    grid-template-columns: 1fr;
  }
}
