@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}

.faq-wrapper {
  width: 100%;
  font-family: "Noto Sans Hebrew", sans-serif;
}

.faq-container {
  margin: 0 auto;
  padding: 4rem;
  width: 80%;
  direction: rtl;
}

.faq-accordion .faq-accordion-item {
  border-bottom: 1px solid #e5e5e5;
}
.faq-accordion .faq-accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #03b5d2;
}
.faq-accordion button {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically */
  text-align: right; /* Text alignment to right */
  direction: rtl; /* Right-to-left text direction */
  width: 100%;
  padding: 1em 0;
  color: #7288a2;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}
.faq-accordion button:hover,
.faq-accordion button:focus {
  cursor: pointer;
  color: #03b5d2;
}
.faq-accordion button:hover::after,
.faq-accordion button:focus::after {
  cursor: pointer;
  color: #03b5d2;
  border: 1px solid #03b5d2;
}
.faq-accordion button .faq-accordion-title {
  padding-right: 4px; /* Adjust spacing between icon and title */
}
.faq-accordion button .faq-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
  margin-left: 4px; /* Adjust spacing between icon and title */
}
.faq-accordion button .faq-icon::before {
  display: block;
  content: "";
  position: absolute; /* Position the pseudo-element */
  top: 50%; /* Move it to the middle vertically */
  left: 98.4%; /* Move it to the middle horizontally */
  transform: translate(-50%, -50%); /* Center it properly */
  width: 10px;
  height: 2px;
  background: currentColor;
}
.faq-accordion button .faq-icon::after {
  display: block;
  content: "";
  position: absolute; /* Position the pseudo-element */
  top: 50%; /* Move it to the middle vertically */
  left: 98.4%; /* Move it to the middle horizontally */
  transform: translate(-50%, -50%); /* Center it properly */
  width: 2px;
  height: 10px;
  background: currentColor;
}
.faq-accordion button[aria-expanded="true"] {
  color: #03b5d2;
}
.faq-accordion button[aria-expanded="true"] .faq-icon::after {
  width: 0;
}
.faq-accordion button[aria-expanded="true"] + .faq-accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.faq-accordion .faq-accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.faq-accordion .faq-accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .faq-container {
    margin: 0 auto;
    padding: 4rem;
    width: 100%;
    direction: rtl;
  }
}
