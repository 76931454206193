@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

@keyframes arrowBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.bouncing-arrow {
  display: inline-block;
  animation: arrowBounce 1s infinite;
}

/*FILE POPUP CSS*/
.delete-file-popup-header-delete-show {
  margin-top: 17px;
  font-family: "Noto Sans Hebrew", sans-serif;
}
.file-show-popup-overlay,
.file-content-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.file-show-popup,
.file-content-popup {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.files-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.file-show-popup ul {
  list-style: none;
  padding: 0;
}

.file-show-popup li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.file-show-popup li span {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.file-show-popup li button {
  background: rgb(250, 248, 245);
  color: rgb(48, 47, 47);
  border: none;
  font-weight: 900;
  cursor: pointer;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Noto Sans Hebrew", sans-serif;
}

.file-show-popup li button:hover {
  background: rgb(250, 248, 245);
  color: rgb(104, 103, 103);
  border: none;
  font-weight: 900;
  cursor: pointer;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Noto Sans Hebrew", sans-serif;
}

.file-content-popup img {
  max-width: 100%;
  height: auto;
}

/**********/

.show-files-uploaded {
  font-family: "Noto Sans Hebrew", sans-serif;
  color: #0044ff;
  cursor: pointer;
  padding: 12px;
}

.show-files-uploaded:hover {
  font-family: "Noto Sans Hebrew", sans-serif;
  color: #4270ec;
  cursor: pointer;
  padding: 12px;
}

.paypal-popup-container {
  position: fixed;
  top: 10%; /* Ensures 10% from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 999; /* Ensure the popup is above other content */
  max-height: calc(
    90vh - 20px
  ); /* Maximum height: 90% of viewport height minus 20px */
  overflow-y: auto; /* Enable vertical scrollbar if content overflows */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  width: 30%;
  height: fit-content;
}
.paypal-overlay-screen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 99999999999;
}

.paypal-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  height: fit-content;
  .paypal-wrapper {
    height: fit-content;
  }
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 998; /* Ensure the background is below the popup */
  pointer-events: none; /* Make the background non-clickable */
}

.terms-and-condition-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 9998; /* Behind the popup */
}

.paypal-popup-header {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px; /* Add padding to create space for the close button */
}

.paypal-popup-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 5px; /* Add margin to separate the button from the content */
  margin-left: 5px; /* Add margin to separate the button from the side */
}

.terms-and-condition-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  max-width: 50%;
  width: 39%; /* Limit width to 80% of the screen */
  overflow: auto; /* Allow scrolling */
  border-radius: 50px;
}

.terms-and-condition-popup-content {
  direction: rtl; /* Right-to-left direction */
  height: 100%;
}

.terms-and-condition-popup-header {
  padding: 1rem;
  font-family: "Noto Sans Hebrew", sans-serif;

  border-bottom: 1px solid #cccccc;
  text-align: center;
}

.terms-and-condition-popup-body {
  padding: 1rem;
}

.terms-and-condition-scrollable-content {
  width: 100%; /* Fixed width of 90% of the popup width */
  overflow-y: auto;
  height: 60vh;
  background-color: #ffffff; /* Background color of the content */
  border-radius: 8px; /* Border radius for rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  padding: 0.5rem;
  font-family: "Noto Sans Hebrew", sans-serif;
}

.terms-and-condition-scrollable-content::-webkit-scrollbar {
  width: 10px; /* Set width of the scrollbar */
}

.terms-and-condition-scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.terms-and-condition-scrollable-content::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
}

.terms-and-condition-scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.terms-and-condition-checkbox-container {
  margin: 1rem 0;
}

.terms-and-condition-popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.terms-and-condition-cancel-button {
  padding: 10px;
  border-radius: 19px;
  margin-left: 1rem;
  cursor: pointer;
  background-color: transparent;
}

.terms-and-condition-confirm-button {
  margin-left: 1rem;
  background-color: #4270ec;
  border-radius: 19px;
  padding: 10px;
  color: aliceblue;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.terms-and-condition-confirm-button:hover {
  background-color: #3250b3; /* Change background color on hover */
}

.terms-and-condition-confirm-button-disabled {
  margin-left: 1rem;
  background-color: #535f80;
  border-radius: 19px;
  padding: 10px;
  color: aliceblue;
}

/* Ensure text doesn't break mid-word */
.terms-and-condition-scrollable-content p {
  white-space: pre-wrap; /* Break lines at whitespace */
  overflow-wrap: break-word; /* Break long words */
}

.terms-and-condition-checkbox-container {
  display: flex;
  align-items: start;
  flex-direction: column;
  font-family: "Noto Sans Hebrew", sans-serif;
}
.terms-and-condition-check-box {
  display: flex;
  justify-content: center;
  padding-bottom: 9px;
  font-family: "Noto Sans Hebrew", sans-serif;
  cursor: pointer;
}
.terms-and-condition-check-box-to-check {
  padding: 5px;
  margin-left: 6px;
  font-family: "Noto Sans Hebrew", sans-serif;
  cursor: pointer;
}
@keyframes blurIn {
  from {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

.about__container > .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
}
.about__buttons {
  display: flex;
  align-items: center;
  .checkout-button {
    width: 230px;
    height: 60px;
    font-size: 16px;
    margin-top: 10px;
    background-color: transparent;
    color: #4270ec;
    border-color: #4270ec;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.6s ease; /* Add transition for background color */
    font-weight: bold;
  }
  .checkout-button:hover {
    width: 230px;
    height: 60px;
    font-size: 16px;
    margin-top: 10px;
    background-color: #4270ec;
    color: white;
    border-color: #4270ec;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.6s ease; /* Add transition for background color */
  }
  .btn.btn-primary {
    width: 230px;
    height: 60px;
    font-size: 16px;
    margin-top: 10px;
  }
  .our-project-link {
    width: 200px;
    height: 60px;
    font-size: 25px;
    margin-top: 10px;
  }
}
.about__container {
  position: relative;
  animation: blurIn 0.8s ease-in-out;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    .title-header {
      display: flex;
      direction: rtl;
      font-size: 65px;
      font-family: "Noto Sans Hebrew", sans-serif;
    }
    .text {
      font-family: "Noto Sans Hebrew", sans-serif;
      display: flex;
      direction: rtl;
      font-size: 35px;
    }
  }
}
.about__container::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 50rem;
  width: 40%;
  background: var(--linear-bg);
  z-index: -1;
  border-radius: var(--radius) 0 0 var(--radius);
  box-shadow: var(--box-shadow);
}
.about__buttons button {
  margin-right: 1.5rem;
  font-family: "Noto Sans Hebrew", sans-serif;
}
.about__buttons a span {
  margin-right: 10px;
  font-family: "Noto Sans Hebrew", sans-serif;
}
.about__image img {
  width: 90%;
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
}
.about__image {
  position: relative;
  .livingroom-img {
    height: 42rem;
    /* Increase the height to 150% of its original height */
    width: 32.4rem;
  }
}
.popup-with-price {
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
  width: 100%;
}

.CircularProgressbar {
  width: 4rem;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--color-blue-primary);
}
.CircularProgressbar .CircularProgressbar-text {
  fill: var(--color-blue-primary);
}
.about__progress {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1.4rem;
  background: white;
  border-radius: var(--radius);
  padding: 1rem;
  max-width: 226px;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: -10%;
  box-shadow: var(--box-shadow);
}
.about__progress h4 {
  width: max-content;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  .about__container .container {
    display: flex;
    flex-direction: column-reverse;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;
      .title-header {
        display: flex;
        direction: rtl;
        font-size: 45px;
      }
      .text {
        display: flex;
        direction: rtl;
        font-size: 20px;
      }
    }
  }
  .about__container::before {
    display: none;
  }
  .about__buttons {
    .checkout-button {
      width: 195px;
      height: 60px;
      font-size: 16px;
      margin-top: 10px;
    }
    .checkout-button:hover {
      width: 195px;
      height: 60px;
      font-size: 16px;
      margin-top: 10px;
    }
    .btn.btn-primary {
      width: 205px;
      height: 60px;
      font-size: 16px;
      margin-top: 10px;
    }
    .our-project-link {
      width: 200px;
      height: 60px;
      font-size: 23px;
      margin-top: 10px;
    }
  }
  .about__image {
    position: relative;
    .livingroom-img {
      margin-top: 60px;
      height: 170%; /* Increase the height to 150% of its original height */
      width: 110%;
    }
  }

  .terms-and-condition-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    max-width: 50%;
    width: 80%; /* Limit width to 80% of the screen */
    max-height: 80%;
    overflow: auto; /* Allow scrolling */
    border-radius: 50px;
  }
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .about__container .container {
    width: var(--container-width-lg) !important;
    display: flex;
    justify-content: start;
    flex-direction: column-reverse;
    padding: 0px;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;
      .title-header {
        display: flex;
        direction: rtl;
        font-size: 40px;
      }
      .text {
        display: flex;
        direction: rtl;
        font-size: 18px;
      }
    }
  }
  .about__buttons {
    display: grid;
    justify-content: center;
    gap: 2rem;
  }
  .about__buttons {
    .checkout-button {
      width: 180px;
      height: 50px;
      font-size: 13px;
      margin-top: 10px;
    }
    .checkout-button:hover {
      width: 180px;
      height: 50px;
      font-size: 13px;
      margin-top: 10px;
    }
    .btn.btn-primary {
      width: 180px;
      height: 50px;
      font-size: 13px;
      margin-top: 10px;
    }
    .our-project-link {
      width: 200px;
      height: 60px;
      font-size: 18px;
      margin-top: 10px;
    }
  }
  .about__buttons button {
    margin: 0;
  }
  .about__buttons a {
    text-align: center;
  }
  .about__image {
    .livingroom-img {
      height: 26rem; /* Increase the height to 150% of its original height */
      width: 21rem;
    }
  }
  .about__progress {
    margin-top: 88px;
  }
  .terms-and-condition-popup {
    top: 42%;
    max-width: 100%;
    max-height: 120%;
    height: fit-content;
    width: 70%;
  }
  .terms-and-condition-scrollable-content {
    height: 50vh;
  }
  .terms-and-condition-cancel-button {
    padding: 10px;
    border-radius: 19px;
    margin-left: 1rem;
    cursor: pointer;
    background-color: transparent;
  }

  .terms-and-condition-confirm-button {
    margin-left: 1rem;
    background-color: #4270ec;
    border-radius: 19px;
    padding: 10px;
    color: aliceblue;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add transition for background color */
  }

  .terms-and-condition-confirm-button:hover {
    background-color: #3250b3; /* Change background color on hover */
  }
  .show-files-uploaded {
    margin: 19px;
    margin-bottom: 0px;
    font-size: 15px;
  }
}
