@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

.container__portfolio {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
}
.portfolio__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  padding-left: 3rem;
}

.portfolio__header button {
  width: fit-content;
}
.iframe-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Iframe container styles */
.iframe-container {
  position: relative;
  width: 80%; /* Adjust as needed */
  height: 80%; /* Adjust as needed */
  background-color: #fff; /* White background for the iframe */
  border-radius: 10px;
  overflow: hidden;
}

/* Iframe close button styles */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 15px 25px;
  background-color: #ccc; /* Gray background for the button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

/* Iframe content styles */
.iframe-content {
  width: 100%;
  height: 100%;
  border: none; /* Remove border from iframe */
}
.works-for-example-title {
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
  padding: 17px;
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .container__portfolio {
    grid-template-columns: 1fr;
  }
  .iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Iframe container styles */
  .iframe-container {
    position: relative;
    width: 80%; /* Adjust as needed */
    height: 80%; /* Adjust as needed */
    background-color: #fff; /* White background for the iframe */
    border-radius: 10px;
    overflow: hidden;
  }

  /* Iframe close button styles */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #ccc; /* Gray background for the button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Iframe content styles */
  .iframe-content {
    width: 100%;
    height: 100%;
    border: none; /* Remove border from iframe */
  }
}
