@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");
@keyframes blurIn {
  from {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

.services__intro {
  animation: blurIn 0.8s ease-in-out;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  align-items: center;
  .text {
    direction: rtl;
    font-size: 35px;
    font-family: "Noto Sans Hebrew", sans-serif;
  }
  .text-important-to-mention {
    margin-top: 80px;
    direction: rtl;
    font-size: 17px;
    font-family: "Noto Sans Hebrew", sans-serif;
    color: gray;
  }
  .our-benefits-title {
    font-size: 65px;
    direction: rtl;
    font-family: "Noto Sans Hebrew", sans-serif;
  }
  .our-benefits-title-under-title {
    margin-top: 10px;
    font-size: 35px;
    direction: rtl;
    font-family: "Noto Sans Hebrew", sans-serif;
  }
}
.services__intro > div:last-of-type {
  width: 100%;
  position: relative;

  .our-benefits-title {
    direction: rtl;
  }
}
.services__intro__image img {
  width: 400px;
  height: 490px;
  object-fit: cover;
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
}

.services__intro__miniSlider {
  width: 10.4rem;
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
  padding: 1rem 1.4rem 2.4rem 1rem;
  position: absolute;
  top: 50%;
  left: 400px;
  transform: translate(-50%);
  background: white;
  z-index: 2;
  .swiper-inner-text {
    font-size: 17px;
    font-family: "Noto Sans Hebrew", sans-serif;
  }
}
.icon-steps-wrapper {
  display: flex;
  width: 50%;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
}
.services__intro__miniSlider {
  display: flex;
  align-items: start;
  justify-content: center;
  justify-content: end;
  .icon {
    color: var(--color-blue-primary);
    font-size: 4.2rem;
    padding-left: 58px;
  }
}
.icone-wrapper {
  padding: 25px;
  min-width: 370px;
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
}

.two-icone-wrapper {
  display: flex;
  width: 100%;
}
.services__intro__miniSlider p {
  font-size: 0.8rem;
  line-height: 1.4;
  font-weight: 500;
}
.call-for-action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  .btn-primary {
    width: 210px;
    margin-top: 17px;
    font-family: "Noto Sans Hebrew", sans-serif;
    direction: rtl;
  }
}
.services__servicesComp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: var(--linear-bg);
  border-radius: var(--radius);
  padding: 3rem 8rem;
  margin-top: 5rem;
}
.services__servicesComp h1 {
  margin-bottom: 3.5rem;
}
.services__servicesComp h4 {
  margin: 0.7rem 0;
}

.services__servicesComp h1,
.services__servicesComp h4 {
  color: rgb(80, 79, 79);
}
.services__servicesComp .icon {
  background: var(--color-light-gray);
  display: flex;
  width: min-content;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.services__servicesComp > div:last-of-type {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem; */
}

.call-for-action-phase {
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
  font-size: 1.875em;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 1px 1px 1px #888888;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .services__intro {
    grid-template-columns: 1fr;
    gap: 3.5rem;
    .text {
      direction: rtl;
      font-size: 20px;
    }
    .our-benefits-title {
      direction: rtl;
      font-size: 45px;
    }
    .our-benefits-title-under-title {
      margin-top: 10px;
      font-size: 25px;
      direction: rtl;
      font-family: "Noto Sans Hebrew", sans-serif;
    }
  }
  .services__servicesComp {
    grid-template-columns: 1fr;
    padding: 1.5rem 4rem;
  }
  .services__intro__miniSlider {
    .swiper-inner-text {
      font-size: 17px;
    }
  }

  .services__servicesComp {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    /* background: var(--linear-bg); */
    border-radius: var(--radius);
    margin-top: 5rem;
  }

  .two-icone-wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .icon-steps-wrapper {
    display: flex;
    width: 100%;
  }
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm) !important;
  }
  .services__intro {
    max-width: 310px;
    .text {
      direction: rtl;
      font-size: 18px;
    }
    .our-benefits-title {
      direction: rtl;
      font-size: 40px;
    }
    .our-benefits-title-under-title {
      margin-top: 10px;
      font-size: 22px;
      direction: rtl;
      font-family: "Noto Sans Hebrew", sans-serif;
    }
  }
  .services__intro__image {
    transform: scale(0.7);
  }
  .services__intro__miniSlider {
    transform: scale(0.8);
    left: 0;
  }
  .services__servicesComp {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .icone-wrapper {
    padding: 28px;
    margin-right: 40px;
    min-width: 110px;
    font-family: "Noto Sans Hebrew", sans-serif;
    direction: rtl;
    .call-for-action-header {
      width: 140px;
      height: 83px;
    }
    .call-for-action-description {
      width: 140px;
    }
  }
  .services__intro__text {
    max-width: 91%;
  }
  .two-icone-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
  }
  .icon-steps-wrapper {
    width: 120%;
  }
  .call-for-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin-right: 80px;
    .call-for-action-phase {
      box-shadow: 0px;
    }
  }
  .container__services {
    max-width: 100%;
  }
  .services__servicesComp {
    max-width: 100%;
    overflow: hidden;
  }
}

/* ================ MEDIA QUERIES (ULTRA SMALL DEVICES) ============= */
@media screen and (max-width: 450px) {
  .container {
    width: var(--container-width-sm) !important;
  }

  .services__intro__image {
    transform: scale(0.7);
  }
  .services__intro__miniSlider {
    transform: scale(0.8);
    left: 0;
  }
  .services__servicesComp {
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    align-items: end;
    max-width: 100%;
    padding: 0px;
  }

  .icone-wrapper {
    padding: 18px;
    margin-right: 0px;
    min-width: 110px;
    font-family: "Noto Sans Hebrew", sans-serif;
    direction: rtl;
    .call-for-action-header {
      width: 140px;
      height: 83px;
    }
    .call-for-action-description {
      width: 140px;
    }
  }
  .services__intro__text {
    max-width: 91%;
  }
  .two-icone-wrapper {
    display: flex;
    width: 100%;
  }
  .icon-steps-wrapper {
    width: 140%;
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .call-for-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0px;
    margin-left: 10%;
    .call-for-action-phase {
      box-shadow: 0px;
    }
  }
  .container__services {
    max-width: 100%;
  }
  .services__intro {
    max-width: 350px;
    overflow-x: hidden;
    .our-benefits-title {
      direction: rtl;
      font-size: 34px;
      margin-right: 13px;
    }
    .our-benefits-title-under-title {
      margin-top: 10px;
      font-size: 18px;
      direction: rtl;
      font-family: "Noto Sans Hebrew", sans-serif;
      margin-right: 13px;
    }
    .text {
      margin-right: 13px;
      direction: rtl;
      font-size: 17px;
    }
    .text-important-to-mention {
      font-size: 14px;
      margin-right: 13px;
    }
  }
}
