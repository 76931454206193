@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

.container.container__nav {
  .links {
    .active {
      font-size: 20px;
      font-family: "Noto Sans Hebrew", sans-serif;
    }
    .not-active {
      font-size: 20px;
      font-family: "Noto Sans Hebrew", sans-serif;
    }
  }
}
.container__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container__nav > div:last-of-type {
  display: flex;
  align-items: center;
}
.links {
  display: flex;
  gap: 1.7rem;
}
.links a {
  font-size: 0.87rem;
  font-weight: 500;
  text-transform: capitalize;
}
.links a.active {
  color: var(--color-blue-primary);
}
.links__button {
  order: 5;
}
.search::after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #2622508a;
  transition: opacity 400ms ease;
}
.search.visible::after {
  opacity: 1;
  z-index: 0;
}
.search__container {
  position: relative;
}
.search__button {
  position: relative;
  z-index: 2;
}
.search__box {
  position: absolute;
  right: -0.4rem;
  top: -0.25rem;
  z-index: -10;
  opacity: 0;
  transition: opacity 400ms ease;
}
.search__box.visible {
  z-index: 1;
  opacity: 1;
}
.search__box input {
  padding: 0.3rem 1.5rem 0.3rem 0.7rem;
  border-radius: 2rem;
  outline: none;
  border: 1px solid var(--color-violet);
  color: var(--color-violet);
}
.menu {
  font-size: 1.4rem;
  border: none !important;
  display: block;
}
.hide__button {
  display: none;
}
/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
  .hide__links {
    display: none;
  }
  .hide__button {
    display: block;
  }
  .links {
    flex-direction: column;
    position: fixed;
    z-index: 11;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--color-blue-primary);
    gap: 0;
    padding-top: 3rem;
  }
  .links a {
    display: block;
    padding: 1rem 5rem;
    color: white;
  }
  .links a:hover {
    background: var(--color-violet);
    color: white !important;
  }
  .links a.active {
    color: var(--color-violet);
  }
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
}
