@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

.portfolio__work {
  box-shadow: var(--box-shadow);
  border-radius: var(--radius);
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Add this to stack elements on top of each other on small screens */
  /* background-image: url("./imgs/filledFloorPlan.JPG"); */
  /* background-size: contain; Ensures the image is contained within the div */
  /* background-position: center; Centers the image */
  /* background-repeat: no-repeat; Prevents the image from repeating */
}

.portfolio__work img {
  border-radius: var(--radius);
  /* object-fit: cover; */
  display: flex;
  /* display: block; */
  width: 100%;
  height: 20rem;
}

.header-and-text-wrapper-work {
  padding: 3%;
  padding-bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.portfolio__work h2 {
  margin: 0.5rem 0;
}

.link-header {
  color: var(--color-blue-primary);
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
}

.text {
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .portfolio__work {
    flex-direction: column; /* Stack elements vertically */
    padding: 0.5rem;
  }

  .portfolio__work img {
    height: auto; /* Adjust the height to auto for better responsiveness */
  }

  .header-and-text-wrapper-work {
    align-items: flex-end; /* Align items to the start */
    padding: 0.5rem;
  }

  .portfolio__work h2 {
    font-size: 1.25rem; /* Adjust the font size for smaller screens */
  }

  .link-header {
    font-size: 1rem; /* Adjust the font size for smaller screens */
  }

  .text {
    font-size: 0.875rem; /* Adjust the font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .portfolio__work {
    padding: 0.25rem;
  }

  .header-and-text-wrapper-work {
    padding: 0.25rem;
  }

  .portfolio__work h2 {
    font-size: 1rem; /* Further adjust the font size for very small screens */
  }

  .link-header {
    font-size: 0.875rem; /* Further adjust the font size for very small screens */
  }

  .text {
    font-size: 0.75rem; /* Further adjust the font size for very small screens */
  }
}
