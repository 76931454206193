@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");

.payment-progress-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans Hebrew", sans-serif;
}

.payment-progress-icon-container {
  margin-top: 3%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 70%;
}

.payment-progress-container {
  margin-top: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .payment-progress-header {
    font-weight: 1000;
    padding: 1.3%;
  }
  .payment-progress-property-type-header {
    direction: rtl;
    font-size: 150%;
    font-weight: 900;
    margin-top: 2%;
    padding: 3%;
  }
}
.payment-progress-number-of-floors-wrapper {
  display: flex;
  margin-top: 5%;
  width: 70%;
  align-items: center;
  justify-content: space-between;
}

.payment-progress-next-back-buttons-wrapper {
  display: flex;
  width: 90%;
  margin-top: 5%;
  .payment-progress-next-back-buttons {
    font-size: 130%;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
/* @media screen and (max-width: 600px) {
  .faq-container {
    margin: 0 auto;
    padding: 4rem;
    width: 100%;
    direction: rtl;
  }
} */
