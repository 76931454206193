@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Hebrew");

.paypal-popup-container {
  max-width: 30%;
  min-height: 43%;
  height: fit-content;
}

.double-email-check-validation-email-popup-content {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.input-email-validation {
  font-family: "Noto Sans Hebrew", sans-serif;
}

.double-email-check-validation {
  font-family: "Noto Sans Hebrew", sans-serif;
  direction: rtl;
  height: fit-content;
}

.double-email-check-validation-email-popup-content h2 {
  text-align: center;
}

.double-email-check-validation-form-group {
  margin-bottom: 15px;
}

.double-email-check-validation-form-group label {
  display: block;
  margin-bottom: 5px;
}

.double-email-check-validation-form-group input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.double-email-check-validation-submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 19px;
  padding: 10px 20px;
  cursor: pointer;
}

.double-email-check-validation-submit-button:hover {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.double-email-check-validation-error-message {
  color: red;
  margin-top: 5px;
}

.double-email-check-validation-form-group.error input[type="email"] {
  border-color: red;
}
.terms-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
  .paypal-popup-container {
    min-width: 90%;
    min-height: 43%;
    height: fit-content;
  }
}
